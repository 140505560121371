.contact {
  position: absolute;
  top: 290%;
  right: 35%;
  margin: 50px 0;
}

.contact h1 {
  text-align: center;
}

.contact-link {
  margin: 40px 0;
}

.contact-link a {
  margin: 0 20px;
}

.contact-link-btn {
  font-size: 20px;
  padding: 12px 18px;
  transition: transform .25s;
}

.contact-link-btn:hover {
  transform: scale(1.1);
}

@media screen and (max-width : 599px) {
  .contact {
    top: 540%;
    right: auto;
  }

  .contact h1,
  .contact h2 {
    font-size: 1.4rem;
  }

  .contact-link {
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
  }

  .contact-link a {
    margin: 0 2%;
  }

}