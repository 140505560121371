.home {
  position: absolute;
  top: 35%;
  right: 36%;
  font-weight: 300;
  color: #eee;
  font-size: 80px;
  transform: skewY(+4deg);;
}

.home h1 {
  font-weight: 700;
  font-size: 60px;
  text-align: center;
}

.typewriter {
  font-size: 50px;
  font-weight: 700;
}

/* --- Home background --- */
.background {
  background: linear-gradient(135deg, #410a84, #31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(100% + 14vh);
  position: absolute;
  top: -20vh;
  transform: skewY(-4deg);
  width: 100%;
}


/* smartphone */
@media screen and (max-width : 599px) {
  .home {
    overflow: hidden;
    right: auto;
  }

  .home h1 {
    font-size: 30px;
    right: auto;
  }

  .typewriter {
    font-size: 24px;
  }
}

/* tablet */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .home {
      right: 20%;
    }
}
