.about {
  position: absolute;
  top: 930px;
  text-align: left;
  padding: 0 6%;
}

.about-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about h1 {
  margin: 3% 0;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
}

.about-article {
  width: 60%;
  margin-right: 6%;
}
.about-image {
  width: 20%;
  margin: auto;
}

.about p {
  margin-bottom: 30px;
  font-size: 1.3rem;
  line-height: 1.6rem;
}

.about-skills {
  margin: 6% auto;
}


.contact-me {
  border: 1px solid;
  padding: 1% 5%;
  border-radius: 10px;
  font-size: 20px;
  color: #64bcd7;
  text-decoration: none;
}

.contact-me:hover {
  text-decoration: none;
  background-color: #64bcd7;
  color: aliceblue;
}

/* ---Smartphone--- */ 
@media screen and (max-width : 599px) {
  .about {
    top: 98%;
  }

  .about-container {
    display: block;
  }

  .about-article {
    width: 100%;
  }

  .about-article p {
    font-size: 1.1rem;
    }

  .about-image {
    width: 60%;
    margin: 0 20%;
  }

  
}
