@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Cabin, "Open Sans", sans-serif; */
}

h1 {
  font-size: 45px;
  margin-bottom: 30px;
  text-align: left;
}

h2,
h3 {
  font-size: 45px;
  text-align: center;
}

p {
  font-size: 20px;
  display: inline-block;
}

hr {
  background-image: linear-gradient(90deg,transparent,rgba(0,0,0,.75),transparent);
  border-bottom: 0;
  height: 1px;
}

body {
  font-family: 'Nunito', sans-serif;
}

li {
  list-style: none;
}

ul {
  padding: 0;
}

.main {
  text-align: center;
}

.navbar-brand {
  display: flex;
}

.logo {
  height: auto;
  width: 30%;
}

/* ----------header---------- */
.navbar-inner {
  position: fixed;
  height: 45px;
  width: 100%;
  background-color: rgba(107,107, 206, 0.45);
  box-shadow: -5px 10px 10px 10px rgba(107,107, 206, 0.45);
  padding: 1% 1% 0;
}

.hamburgerBtn,
.languageBtn {
  margin-right: 2%;
}

.hamburgerInside {
  flex-grow: 0;
  text-align: right;
  
}

.header-link-name {
  white-space: nowrap;
}

.header-link-name:after {
  content: '';
    width: 0;
    transition: all 0.3s ease;
    border-bottom: 3px solid #000;
    display: block;
}

.header-link-name:hover:after {
  width: 100%;
  border-bottom: solid 5px #7550b6;
}

.dropdown-toggle::after {
  color: rgb(255 255 255);
  position: relative;
  top: 4px;
}

.dropdown-menu {
  position: absolute;
  right: 1px;
}

.btn-link:hover {
  color: rgba(0,0,0,.55);
  opacity: .5;
}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: none;
}

.language-btn {
  padding: 0;
  margin-left: 10px;
}

.language-btn:hover {
  opacity: .5;
}


/* ----------footer---------- */
.footer {
  margin-top: 60px;
  position: absolute;
  top: 330%;
  width: 100%;
}

.particles-footer {
  height: 30%;
}

.footer-background {
  background: linear-gradient(90deg,#410a84,#31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(28% + 12vh);
  position: absolute;
  left: 0;
  -webkit-transform: skewY(-5deg);
          transform: skewY(-5deg);
  width: 100%;
}

.footer-background2 {
  color: #fff;
  background: linear-gradient(90deg, #410a84, #31a7bb);;
  height: calc(30% + 12vh);
  padding: 6% 0 2%;
  position: relative;
  top: 76px;
}


/* ---------RESPONSIVE---------- */

/* smartphone */
@media screen and (max-width : 599px) {
  .navbar-inner {
    padding-left: 10%;
  }

  .footer {
    top: 584%;
  }

  .hamburgerInside {
    background-color: #463e97e0;
    margin: 2% 0 0 55%;
    padding-left: 2%;
  }

  .hamburgerInside a {
    text-align: left;
  }

  .hamburgerBtn {
    margin: 2% 0 0 78%;
  }

  .dropdown-menu {
    position: absolute;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

/* tablet */
@media screen and (min-width:600px) and (max-width:1024px) {
  .hamburgerBtn {
      margin: 1% 0 0 90%;
    }
}

.home {
  position: absolute;
  top: 35%;
  right: 36%;
  font-weight: 300;
  color: #eee;
  font-size: 80px;
  -webkit-transform: skewY(+4deg);
          transform: skewY(+4deg);;
}

.home h1 {
  font-weight: 700;
  font-size: 60px;
  text-align: center;
}

.typewriter {
  font-size: 50px;
  font-weight: 700;
}

/* --- Home background --- */
.background {
  background: linear-gradient(135deg, #410a84, #31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(100% + 14vh);
  position: absolute;
  top: -20vh;
  -webkit-transform: skewY(-4deg);
          transform: skewY(-4deg);
  width: 100%;
}


/* smartphone */
@media screen and (max-width : 599px) {
  .home {
    overflow: hidden;
    right: auto;
  }

  .home h1 {
    font-size: 30px;
    right: auto;
  }

  .typewriter {
    font-size: 24px;
  }
}

/* tablet */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .home {
      right: 20%;
    }
}

.about {
  position: absolute;
  top: 930px;
  text-align: left;
  padding: 0 6%;
}

.about-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about h1 {
  margin: 3% 0;
  text-align: left;
  font-size: 36px;
  font-weight: 600;
}

.about-article {
  width: 60%;
  margin-right: 6%;
}
.about-image {
  width: 20%;
  margin: auto;
}

.about p {
  margin-bottom: 30px;
  font-size: 1.3rem;
  line-height: 1.6rem;
}

.about-skills {
  margin: 6% auto;
}


.contact-me {
  border: 1px solid;
  padding: 1% 5%;
  border-radius: 10px;
  font-size: 20px;
  color: #64bcd7;
  text-decoration: none;
}

.contact-me:hover {
  text-decoration: none;
  background-color: #64bcd7;
  color: aliceblue;
}

/* ---Smartphone--- */ 
@media screen and (max-width : 599px) {
  .about {
    top: 98%;
  }

  .about-container {
    display: block;
  }

  .about-article {
    width: 100%;
  }

  .about-article p {
    font-size: 1.1rem;
    }

  .about-image {
    width: 60%;
    margin: 0 20%;
  }

  
}

.projects {
  padding: 0 auto;
}

.projects-outline {
  -webkit-transform: skewY(+4deg);
          transform: skewY(+4deg);
  position: absolute;
  top: 6%;
  padding: 0 6%;
}

.projects h1 {
  margin: 3% 0;
  color: #fff;
  font-size: 36px;
  font-weight: 600;
} 

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  max-width: 30%;
  margin-bottom: 2%;
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  background-color: #4457a0bd;
}

.card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding: 3% 3% 2% 2%;
  color: #fff;
  border-bottom: 0;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  border-bottom: solid 5px #7550B6;
}

.card p {
  display: inline-block;
  background-color: #dedede;
  margin: 0;
  padding: 1% 3% 1% 6%;
  position: absolute;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  -webkit-clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
}

.project-images {
  width: 80%;
  height: auto;
  padding: 2%;
  margin: 0 auto 2%;
}

.project-image {
  background-color: #fff;
}

.card-link {
  white-space: nowrap;
  position: relative;
}

.card-link a {
  margin: 0 8% 0 0;
  color: #fff;
}

.card-link a:hover {
  color: #7550B6;
}

.github-remark,
.web-remark {
  display: none;
}

.card-link a:hover .github-remark {
  display: block;
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: #7550B6;
  color: #fff;
  border: 2px solid #7550B6;
  border-radius: 5px;
  padding: 0 1%;
  font-size: 14px;
}

.card-link a:hover .web-remark {
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  background-color: #7550B6;
  color: #fff;
  border: 2px solid #7550B6;
  border-radius: 5px;
  padding: 0 1%;
  font-size: 14px;
}


/* --- Project background --- */
.projects-background {
  background: linear-gradient(135deg, #410a84, #31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(100% + 11vh);
  position: absolute;
  top: -20vh;
  -webkit-transform: skewY(-4deg);
          transform: skewY(-4deg);
  width: 100%;
}

.projects-background {
  height: calc(90% + 12vh);
  position: absolute;
  top: 180%;
}


/* smartphone */
@media screen and (max-width : 599px) {
  .projects-background {
    top: 300%;
    height: calc(230% + 12vh);
  }

  .card {
    max-width: 100%;
    margin-bottom: 4%;
  }

  .card-container {
    display: block;
  }

  .particles {
    height: calc(100% - 11vh);
  }
}
.contact {
  position: absolute;
  top: 290%;
  right: 35%;
  margin: 50px 0;
}

.contact h1 {
  text-align: center;
}

.contact-link {
  margin: 40px 0;
}

.contact-link a {
  margin: 0 20px;
}

.contact-link-btn {
  font-size: 20px;
  padding: 12px 18px;
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
}

.contact-link-btn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (max-width : 599px) {
  .contact {
    top: 540%;
    right: auto;
  }

  .contact h1,
  .contact h2 {
    font-size: 1.4rem;
  }

  .contact-link {
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
  }

  .contact-link a {
    margin: 0 2%;
  }

}
.scrollUp-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 60px;
  width: 40px;
  font-size: 20px;
  background-color: #fff;
  color: #64bcd7;
  border-color: #64bcd7;
  border-radius: 30px;
}

.scrollUp-btn:hover {
  background-color: #64bcd7;
  color: #fff;
}



@media screen and (max-width : 599px) {
  .scrollUp-btn {
    bottom: 24px;
    right: 16px;
  }
}

