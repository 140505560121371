.projects {
  padding: 0 auto;
}

.projects-outline {
  transform: skewY(+4deg);
  position: absolute;
  top: 6%;
  padding: 0 6%;
}

.projects h1 {
  margin: 3% 0;
  color: #fff;
  font-size: 36px;
  font-weight: 600;
} 

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  max-width: 30%;
  margin-bottom: 2%;
  transition: transform .25s;
  background-color: #4457a0bd;
}

.card:hover {
  transform: translateY(-10px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding: 3% 3% 2% 2%;
  color: #fff;
  border-bottom: 0;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  border-bottom: solid 5px #7550B6;
}

.card p {
  display: inline-block;
  background-color: #dedede;
  margin: 0;
  padding: 1% 3% 1% 6%;
  position: absolute;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  clip-path: polygon(20px 0%, 100% 0%, 100% 100%, 0% 100%);
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
}

.project-images {
  width: 80%;
  height: auto;
  padding: 2%;
  margin: 0 auto 2%;
}

.project-image {
  background-color: #fff;
}

.card-link {
  white-space: nowrap;
  position: relative;
}

.card-link a {
  margin: 0 8% 0 0;
  color: #fff;
}

.card-link a:hover {
  color: #7550B6;
}

.github-remark,
.web-remark {
  display: none;
}

.card-link a:hover .github-remark {
  display: block;
  position: absolute;
  top: 40px;
  right: 0px;
  background-color: #7550B6;
  color: #fff;
  border: 2px solid #7550B6;
  border-radius: 5px;
  padding: 0 1%;
  font-size: 14px;
}

.card-link a:hover .web-remark {
  display: block;
  position: absolute;
  top: 40px;
  left: 10px;
  background-color: #7550B6;
  color: #fff;
  border: 2px solid #7550B6;
  border-radius: 5px;
  padding: 0 1%;
  font-size: 14px;
}


/* --- Project background --- */
.projects-background {
  background: linear-gradient(135deg, #410a84, #31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(100% + 11vh);
  position: absolute;
  top: -20vh;
  transform: skewY(-4deg);
  width: 100%;
}

.projects-background {
  height: calc(90% + 12vh);
  position: absolute;
  top: 180%;
}


/* smartphone */
@media screen and (max-width : 599px) {
  .projects-background {
    top: 300%;
    height: calc(230% + 12vh);
  }

  .card {
    max-width: 100%;
    margin-bottom: 4%;
  }

  .card-container {
    display: block;
  }

  .particles {
    height: calc(100% - 11vh);
  }
}