@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Cabin, "Open Sans", sans-serif; */
}

h1 {
  font-size: 45px;
  margin-bottom: 30px;
  text-align: left;
}

h2,
h3 {
  font-size: 45px;
  text-align: center;
}

p {
  font-size: 20px;
  display: inline-block;
}

hr {
  background-image: linear-gradient(90deg,transparent,rgba(0,0,0,.75),transparent);
  border-bottom: 0;
  height: 1px;
}

body {
  font-family: 'Nunito', sans-serif;
}

li {
  list-style: none;
}

ul {
  padding: 0;
}

.main {
  text-align: center;
}

.navbar-brand {
  display: flex;
}

.logo {
  height: auto;
  width: 30%;
}

/* ----------header---------- */
.navbar-inner {
  position: fixed;
  height: 45px;
  width: 100%;
  background-color: rgba(107,107, 206, 0.45);
  box-shadow: -5px 10px 10px 10px rgba(107,107, 206, 0.45);
  padding: 1% 1% 0;
}

.hamburgerBtn,
.languageBtn {
  margin-right: 2%;
}

.hamburgerInside {
  flex-grow: 0;
  text-align: right;
  
}

.header-link-name {
  white-space: nowrap;
}

.header-link-name:after {
  content: '';
    width: 0;
    transition: all 0.3s ease;
    border-bottom: 3px solid #000;
    display: block;
}

.header-link-name:hover:after {
  width: 100%;
  border-bottom: solid 5px #7550b6;
}

.dropdown-toggle::after {
  color: rgb(255 255 255);
  position: relative;
  top: 4px;
}

.dropdown-menu {
  position: absolute;
  right: 1px;
}

.btn-link:hover {
  color: rgba(0,0,0,.55);
  opacity: .5;
}

.btn-check:focus+.btn, .btn:focus {
  box-shadow: none;
}

.language-btn {
  padding: 0;
  margin-left: 10px;
}

.language-btn:hover {
  opacity: .5;
}


/* ----------footer---------- */
.footer {
  margin-top: 60px;
  position: absolute;
  top: 330%;
  width: 100%;
}

.particles-footer {
  height: 30%;
}

.footer-background {
  background: linear-gradient(90deg,#410a84,#31a7bb);
  box-shadow: 0 0 50 rgb(60 117 162 / 40%);
  height: calc(28% + 12vh);
  position: absolute;
  left: 0;
  transform: skewY(-5deg);
  width: 100%;
}

.footer-background2 {
  color: #fff;
  background: linear-gradient(90deg, #410a84, #31a7bb);;
  height: calc(30% + 12vh);
  padding: 6% 0 2%;
  position: relative;
  top: 76px;
}


/* ---------RESPONSIVE---------- */

/* smartphone */
@media screen and (max-width : 599px) {
  .navbar-inner {
    padding-left: 10%;
  }

  .footer {
    top: 584%;
  }

  .hamburgerInside {
    background-color: #463e97e0;
    margin: 2% 0 0 55%;
    padding-left: 2%;
  }

  .hamburgerInside a {
    text-align: left;
  }

  .hamburgerBtn {
    margin: 2% 0 0 78%;
  }

  .dropdown-menu {
    position: absolute;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

/* tablet */
@media screen and (min-width:600px) and (max-width:1024px) {
  .hamburgerBtn {
      margin: 1% 0 0 90%;
    }
}
